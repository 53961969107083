import { useState, useContext, memo, useRef, useEffect } from 'react';
import {
  Box, Link, TextField, IconButton, List, ListItem, ListItemAvatar, Tabs, Tab,
  CircularProgress, Avatar, ListItemText, Typography, Stack, Chip, Icon
} from '@mui/material';
import axios from 'axios';
import { getApiRoute } from 'src/services';
import Navbar from './blocks/Navbar.js';
import { AuthContext } from 'src/utils/AuthContext.js';
import { CalendarToday } from '@mui/icons-material';
import { useProfileImage } from 'src/utils/useProfileImage.js';
import { formatSubscribers } from 'src/utils/Utils.js';
import { useIndexedDB } from 'src/utils/useIndexedDB.js';
import SubscribeButton from './blocks/SubscribeButton.js';
import alfie from 'src/assets/alfie.png';
import { Link as RouterLink, useParams, useNavigate, useLocation } from "react-router-dom";
import { useIsMounted } from 'src/utils/Utils.js';
import { useFullHeightPageFix } from 'src/utils/useFullHeightPageFix.js';
import { Helmet } from 'react-helmet';
import { useDimensions } from 'src/utils/useDimensions.js';
import { useTheme } from '@emotion/react';

const MAX_MESSAGES = 26;

// Component to render list of calendars in the AI response
const CalendarsAnswer = memo(({ entry }) => {

  const CalendarAvatar = ({ calendar }) => {
    const profileImage = useProfileImage({ picture: `${calendar.handle}-profileImage.png` });
    return (
      <Avatar alt={calendar.name} src={profileImage}>
        <CalendarToday />
      </Avatar>
    )
  }
  return (
    <>
      {!entry.text && <Typography variant="body1" sx={{ mt: .5, ml: 1.75 }}>Here are some popular calendars:</Typography >}
      <List sx={{ mt: 0, ml: 0, width: "100%" }}>
        {entry.calendars.map((calendar, idx) => (
          <ListItem
            key={calendar._id}
            secondaryAction={<SubscribeButton calendar={calendar} isEventCard={true} />}
            sx={{ '& .MuiListItemSecondaryAction-root': { right: 0 }, pr: 0 }}
          >
            <ListItemAvatar>
              <CalendarAvatar calendar={calendar} />
            </ListItemAvatar>
            <ListItemText
              primary={
                <Link
                  data-testid={`calendar-list-${calendar._id}`}
                  to={`/${calendar.handle}/${calendar._id}`}
                  component={RouterLink}
                  color="inherit"
                  underline="none"
                  variant="h5"
                >
                  {calendar.name}
                </Link>
              }
              secondary={formatSubscribers(calendar.subscribers) ? `${formatSubscribers(calendar.subscribers)}` : ''}
              secondaryTypographyProps={{ variant: "subtitle2" }}
              sx={{
                width: { xs: "40%", sm: "24ch" },
                flex: "none",
                '& h5, & h6': {
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }
              }}
            />
          </ListItem>
        ))}
      </List>
    </>
  )
});


// Component to render the chat interface
const RenderChatInterface = memo(({ chatLog, introMessage, chips, handleChipClick, profileImage }) => {

  const messagesToRender = chatLog.length > MAX_MESSAGES
    ? chatLog.slice(chatLog.length - MAX_MESSAGES)
    : chatLog;

  return (
    <List sx={{ flexGrow: 1 }}>
      {/* Introductory message for the tab */}
      <ListItem sx={{ display: 'flex', alignItems: 'flex-start' }}>
        <Avatar alt="Alfie" size="small" src={alfie} sx={{ mt: 0.5 }} />
        <Box>
          <ListItemText
            sx={{ ml: 1.75, whiteSpace: 'pre-wrap', textWrap: 'balance' }}
            primary={introMessage}
            primaryTypographyProps={{ variant: "body1" }}
          />
          <Stack direction="row" sx={{ flexWrap: 'wrap', mt: 3 }}>
            {chips.map((chip, index) => (
              <Chip
                key={index}
                label={chip.label}
                onClick={() => handleChipClick(chip.message)}
                sx={{ ml: 1, mt: 1 }}
                color="primary"
                variant="outlined"
                size="small"
              />
            ))}
          </Stack>
        </Box>
      </ListItem>

      {/* Rest of essages - Chat log*/}
      {messagesToRender.map((entry, index) => (
        <ListItem key={index} sx={{ alignItems: 'flex-start', mt: 2 }}>
          {entry.sender === 'ai' ? (
            <Avatar alt="Alfie" size="small" src={alfie} sx={{ mt: 0.5 }} />
          ) : (
            <Avatar alt="User" size="small" src={profileImage} sx={{ mt: 0.5 }} />
          )}
          <Box sx={{ flex: "1 1 auto", maxWidth: "100%" }}>
            {entry.text && (
              <ListItemText
                primary={entry.text}
                sx={{ ml: 1.75, whiteSpace: 'pre-wrap', textWrap: 'balance' }}
                primaryTypographyProps={{ variant: "body1" }}
              />
            )}
            {/* If there a re calendars in the AI response, display then */}
            {entry.sender === 'ai' && entry.calendars && entry.calendars.length > 0 && (
              <CalendarsAnswer entry={entry} />
            )}
            {entry.sender === 'ai' && entry.calendars && entry.calendars.length === 0 && (
              <ListItemText
                primary={"No calendars found. Please try searching for something else."}
                sx={{ ml: 1.75, whiteSpace: 'pre-wrap', textWrap: 'balance' }}
                primaryTypographyProps={{ variant: "body1" }}
              />
            )}
          </Box>
        </ListItem>
      ))}
    </List>
  );
});

function Search() {
  const chatRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const isMounted = useIsMounted();
  const mounted = isMounted();
  const { activeTab: activeTabParam } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const containerRef = useRef(null);
  useFullHeightPageFix(containerRef);

  const initialAlfieSearch = {
    searchChatLog: [],
    discoverChatLog: [],
    supportChatLog: [],
    activeTab: 'search',
  };

  const [AlfiSearch, setAlfieSearch, statePropertyFunctions] = useIndexedDB('stanza', 'alfie-search', 'cache', initialAlfieSearch);
  const { searchChatLog, discoverChatLog, supportChatLog, activeTab } = AlfiSearch;
  const { setSearchChatLog, setDiscoverChatLog, setSupportChatLog, setActiveTab } = statePropertyFunctions;
  const [message, setMessage] = useState('');
  const { isMediumScreen } = useDimensions();
  const theme = useTheme();

  // Refs to keep track of the latest chat logs
  const searchChatLogRef = useRef(searchChatLog);
  const discoverChatLogRef = useRef(discoverChatLog);
  const supportChatLogRef = useRef(supportChatLog);

  // Update refs whenever chat logs change
  useEffect(() => {
    searchChatLogRef.current = searchChatLog;
    discoverChatLogRef.current = discoverChatLog;
    supportChatLogRef.current = supportChatLog;
  }, [searchChatLog, discoverChatLog, supportChatLog]);

  const introMessages = {
    search: "Search for a calendar or choose from popular calendars on Stanza:",
    discover: "Hi! I'm Alfie - Stanza's official mascot. I can help you find calendars or events on Stanza. You can also ask me questions about how to plan your weekend or what's going on in your city.\nWhere would you like to start?",
    support: "Hi! I'm Alfie - Stanza's official mascot. I can help you answer questions about Stanza and about your calendar.\nWhere would you like to start?"
  };

  const defaultSearchBarMessage = {
    search: "Search for a specific calendar",
    discover: "Ask me questions about calendars within categories or other similar types of calendars",
    support: "Ask me questions to help troubleshoot issues with your calendar"
  }

  const tabChips = {
    search: [
      { label: "Warriors", message: "Golden State Warriors" },
      { label: "Formula 1", message: "Formula 1" },
      { label: "Reacher on Prime", message: "Reacher" }
    ],
    discover: [
      { label: "Marvel TV & Movies", message: "Marvel TV & Movies" },
      { label: "Bay Area Teams", message: "List Bay Area sports teams" },
      { label: "Reality TV", message: "List Reality TV shows" },
      { label: "Cooking Shows on Netflix", message: "List cooking shows on Netflix" },
      { label: "Help me plan my weekend", message: "Help me plan my weekend" }
    ],
    support: [
      { label: "Delete calendar from Google", message: "How do I delete a calendar from Google Calendar?" },
      { label: "Delete calendar from Apple", message: "How do I delete a calendar from Apple Calendar?" },
      { label: "Delete calendar from Microsoft", message: "How do I delete a calendar from Microsoft Calendar?" },
    ]
  };

  const { user } = useContext(AuthContext);

  useEffect(() => {
    if (!mounted) return;
    const validActiveTab = ['search', 'discover', 'support'].includes(activeTabParam);
    setActiveTab(validActiveTab ? activeTabParam : 'search');
  }, [activeTabParam, setActiveTab, mounted]);

  useEffect(() => {
    if (!mounted) return;
    if (location.state?.term) {
      handleSend(location.state.term);
    }
  }, [location.state?.term, mounted]);

  useEffect(() => {
    if (!mounted) return;
    if (!chatRef.current) return;
    chatRef.current.scrollTo({
      top: chatRef.current.scrollHeight,
      behavior: 'smooth'
    });
  }, [searchChatLog, discoverChatLog, supportChatLog, mounted]);

  useEffect(() => {
    if (mounted && chatRef.current) {
      chatRef.current.scrollTo({
        top: chatRef.current.scrollHeight,
        behavior: "instant"
      });
    }
  }, [activeTab, mounted]);

  const profileImage = useProfileImage(user);

  const handleTabChange = (event, newValue) => {
    if (newValue !== "search") {
      navigate(`/search/${newValue}`);
    } else {
      navigate(`/search`);
    }
  };

  const handleChipClick = (chipMessage) => {
    handleSend(chipMessage);
  };

  const handleSend = async (inputMessage = message) => {
    if (inputMessage.trim()) {
      // Set loading to true
      setIsLoading(true);

      // Determine which chat log to update based on the active tab
      const updateChatLog = {
        search: setSearchChatLog,
        discover: setDiscoverChatLog,
        support: setSupportChatLog
      }[activeTab];

      // Append user message to chat log
      updateChatLog(chatLog => [...chatLog, { sender: 'user', text: inputMessage.trim() }]);

      // Clear the input field
      setMessage('');

      try {
        // Determine the appropriate API route based on the active tab
        const apiRoute = {
          search: "SEARCH",
          discover: "DISCOVER",
          support: "SUPPORT"
        }[activeTab];

        const response = await axios.post(
          getApiRoute("chat", apiRoute),
          {
            message: inputMessage.trim()
          }
        );

        console.log(response.data.response);

        // Replace with your OpenAI response
        updateChatLog(prevLog => [...prevLog, { sender: 'ai', text: response.data.response, calendars: response.data.calendars }]);

      } catch (error) {
        console.error('Error querying OpenAI:', error.message);
      } finally {
        setIsLoading(false);
      }
    }
  };

  // Cleanup effect to trim chat logs to last MAX_MESSAGES on unmount
  useEffect(() => {
    return () => {
      // Trim searchChatLog
      if (searchChatLogRef.current.length > MAX_MESSAGES) {
        setSearchChatLog(searchChatLogRef.current.slice(-MAX_MESSAGES));
      }
      // Trim discoverChatLog
      if (discoverChatLogRef.current.length > MAX_MESSAGES) {
        setDiscoverChatLog(discoverChatLogRef.current.slice(-MAX_MESSAGES));
      }
      // Trim supportChatLog
      if (supportChatLogRef.current.length > MAX_MESSAGES) {
        setSupportChatLog(supportChatLogRef.current.slice(-MAX_MESSAGES));
      }
    };
  }, [setSearchChatLog, setDiscoverChatLog, setSupportChatLog]);

  return (
    <div ref={containerRef} style={{ display: 'flex', flexDirection: 'column', height: '100vh', overflow: 'hidden' }}>
      <Helmet>
        <title>Stanza - Search</title>
        <meta name="description" content="Chat with Alfie, Stanza's official mascot, to find calendars or events on Stanza. Discover new calendars similar to ones that you already like." />
      </Helmet>
      <Navbar user={user} />
      <Box display="flex" flexDirection="column" sx={{ height: "100%", overflow: "hidden", margin: "auto", marginTop: '0px', maxWidth: { xs: '100%', sm: "600px" }, p: 0, flexGrow: 1, minWidth: { xs: '100%', sm: "600px" } }}>
        {/* Tabs */}
        <Tabs value={activeTab} onChange={handleTabChange} variant="fullWidth" sx={{ mx: "0 !important", minHeight: "52.91px", zIndex: "4", position: "relative" }}>
          <Tab label="Search" value="search" disableRipple />
          <Tab label="Discover" value="discover" disableRipple />
          {/* <Tab label="Support" value="support" disableRipple /> */}
        </Tabs>

        {/* Chat Interface */}
        <Box ref={chatRef} id="chat-interface" display="flex" flexDirection="column" sx={{ width: "100%", overflow: 'auto', flexGrow: 1 }}>
          {activeTab === 'search' && (
            <RenderChatInterface chatLog={searchChatLog} introMessage={introMessages.search} chips={tabChips.search} handleChipClick={handleChipClick} profileImage={profileImage} />
          )}
          {activeTab === 'discover' &&
            <RenderChatInterface chatLog={discoverChatLog} introMessage={introMessages.discover} chips={tabChips.discover} handleChipClick={handleChipClick} profileImage={profileImage} />
          }
          {activeTab === 'support' &&
            <RenderChatInterface chatLog={supportChatLog} introMessage={introMessages.support} chips={tabChips.support} handleChipClick={handleChipClick} profileImage={profileImage} />
          }
        </Box>
        {/* Input Field and Send Button */}
        <div style={{ display: 'flex', padding: '10px', marginBottom: "4px" }}>
          <TextField
            fullWidth
            type="search"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onKeyDown={(e) => { if (e.key === 'Enter' && !isLoading) handleSend(); }}
            placeholder="Search for calendars"
            sx={{ background: `${theme.palette.paper.secondary}`, border: "none", py: "10px", pl: "10px", '& .MuiInputBase-root': { pr: "10px" }}}
            InputProps={{
              endAdornment: isLoading ? (
                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: "28px", height: "28px" }}>
                  <CircularProgress thickness={2} size={30} />
                </Box>
              ) : (
                <IconButton variant="contained" onClick={() => handleSend(message)} sx={{ ml: "10px", width: "40px", height: "40px" }}>
                  <Icon fontSize="medium" sx={{ color: 'secondary.main' }} baseClassName="material-symbols-outlined">search</Icon>
                </IconButton>
              ),
            }}
          />
        </div>
      </Box>
    </div>
  );
}

export default Search;


/*
// Unused component to render a list of chips in the AI response
const ChipsAnswer = ({ entry }) => (
  <Box sx={{ mt: entry.text ? 2 : 0 }}>
    <Stack display="flex" flexDirection="row" sx={{ flexWrap: 'wrap', gap: 1, mt: .5, ml: 1.75 }}>
      {entry.calendars.map((calendar, idx) => (
        <Chip
          key={idx}
          label={calendar.name + calendar.formattedFollowers}
          onClick={() => {
            const url = getPageRoute("calendar", "ALL_EVENTS", { handle: calendar.handle });
            window.open(url, '_blank');
          }}
        />
      ))}
    </Stack>
  </Box>
);
*/